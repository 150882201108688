@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html,
  .font-primary {
    font-family: "Open Sans", system-ui, sans-serif;
    @apply text-gray-600;
  }
  .font-secondary {
    font-family: "Quicksand", system-ui, sans-serif;
  }
}

@layer utilities {
  .text-body {
    @apply text-gray-600;
  }
}

html,
body,
#root {
  @apply w-full h-full;
}
